<template>
  <div class="stevedoring-administration bj">
    <div class="auto-table-title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <div class="seach">
        <el-button @click="Addfleet" type="primary">添加</el-button>
      </div>
      <el-button
        :disabled="!tableData.length || disabledExport"
        @click="onHandleExport"
        type="warning"
        icon="el-icon-download"
        >导出</el-button
      >
    </div>

    <div class="auto-table-flex">
      <el-table
        v-loading="loading"
        :data="tableData"
        height="100%"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" align="center" label="序号" width="100" />
        <el-table-column align="center" label="装卸队名称" prop="fleet_name" />
        <!-- show-overflow-tooltip -->
        <el-table-column align="center" label="管理城市仓" min-width="300">
          <template slot-scope="scope">
            <div>{{ logisticsFormat(scope.row.logistics) }}</div>
          </template>
        </el-table-column>

        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-button type="success" plain v-if="scope.row.status == 1"
              >启用</el-button
            >
            <el-button type="warning" plain v-else>禁用</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="personEdit(scope.row)">
              编辑
            </el-button>
            <el-button
              type="text"
              @click="updatePerson(scope.row, scope.$index)"
            >
              {{ scope.row.status == 1 ? "禁用" : "启用" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 添加/装卸队 -->
    <el-dialog
      :center="true"
      @close="personClose"
      :visible.sync="personVisible"
      v-if="personVisible"
      :title="personTitle"
      :append-to-body="true"
    >
      <div style="width: 80%">
        <el-form
          :model="personForm"
          :rules="rules"
          ref="ruleForm"
          label-width="20%"
          class="demo-ruleForm"
        >
          <el-form-item label="装卸队名称" prop="fleet_name">
            <el-input
              v-model="personForm.fleet_name"
              placeholder="请输入装卸队名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理城市仓" prop="logistics_ids">
            <el-select
              v-model="personForm.logistics_id"
              style="width: 100%"
              multiple
              filterable
              @change="logisticschange"
              placeholder="请选择管理城市仓"
            >
              <el-option
                v-for="item in admincity"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="personVisible = false">取消</el-button>
        <el-button type="primary" @click="personSure">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getLogisticFleetListExport } from "@/api/logisticsControl/car-logistics";

export default {
  name: "stevedoring-administration",
  components: {},
  data() {
    return {
      disabledExport: false, // 导出禁用
      //表格搜索分页基础信息
      tableData: [],
      //查询输入框下拉框从此配置
      from: {
        // name: "",
        page: 1,
        pageSize: 10,
      },
      currentPage: 1,
      total: 0,
      loading: false,
      //页面功能字段存储
      personbtn: "",
      personVisible: false,
      personForm: {
        id: "",
        logistics_id: [],
        fleet_name: "", //	string	车队名称
        logistics_ids: "", //	string	城市仓id集合，以,分隔
      },
      personTitle: "",
      admincity: [],
      rules: {
        fleet_name: [
          { required: true, message: "请输入装卸队名称", trigger: "blur" },
        ],
        logistics_ids: [
          {
            required: true,
            message: "请选择管理城市仓",
            trigger: "change blur",
          },
        ],
      },
    };
  },

  created() {
    this.hqlist();
  },
  methods: {
    // hqgetLogisticList() {
    //   this.$api.logistics.getLogisticList({ source: 2 }).then((res) => {
    //     console.log(res, "获取筛选框的接口城市仓");
    //   });
    // },
    hqgetLogisticList() {
      this.$api.logistics.getLogisticList({ source: 1 }).then((res) => {
        console.log(res, "添加的时候获取城市仓");
        this.admincity = res.data;
      });
    },
    /**
     * 格式化城市仓
     */
    logisticsFormat(e) {
      return e ? e.map((el) => el.name).join("，") : "";
    },
    //添加按钮
    Addfleet() {
      this.personTitle = "添加";
      Object.assign(this.$data.personForm, this.$options.data().personForm);
      this.hqgetLogisticList();
      this.personVisible = true;
      this.personbtn = "add";
    },
    logisticschange() {
      this.personForm.logistics_ids = this.personForm.logistics_id
        ? this.personForm.logistics_id.join(",")
        : "";
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        // 组合需要的参数
        const query = {
          ...this.from,
        };
        await getLogisticFleetListExport(query);
      } catch (err) {
        console.log("ajax getLogisticFleetListExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    //编辑点击保存
    personSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.personbtn == "add") {
            this.$api.logistics.addFleet(this.personForm).then((res) => {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.personbtn = "";
              this.hqlist();
              this.personVisible = false;
            });
          } else {
            this.$api.logistics.updateFleet(this.personForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.personbtn = "";
              this.hqlist();
              this.personVisible = false;
            });
          }
        }
      });
    },
    //编辑弹框点击关闭
    personClose() {
      this.personVisible = false;
    },
    //编辑接口
    personEdit(detail) {
      this.personTitle = "编辑";
      this.personbtn = "edit";
      this.admincity = [];
      this.$api.logistics.getLogisticList({ source: 1 }).then((res1) => {
        this.admincity = res1.data;

        this.$api.logistics.getFleetInfo({ id: detail.id }).then((res) => {
          console.log(res, "1111111");
          this.personForm = JSON.parse(JSON.stringify(res.data));

          // this.admincity=[...this.admincity,...res.data.logistics]

          let ids = JSON.parse(JSON.stringify(res.data.logistics));
          this.personForm.logistics_id = JSON.parse(JSON.stringify(ids)).map(
            (el) => el.id
          );
          this.personForm.logistics_ids = ids.map((el) => el.id).join(",");
          this.personForm = JSON.parse(JSON.stringify(this.personForm));
          this.admincity = this.admincity.concat(res.data.logistics);
          console.log(this.personForm);
          console.log(this.admincity, "添加的时候获取城市仓");
          this.personVisible = true;
        });
      });
    },
    //启用禁用
    updatePerson(detail, index) {
      this.$confirm(
        `确定要${detail.status == 1 ? "禁用" : "启用"}吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.logistics
            .updateFleetStatus({
              id: detail.id,
              status: detail.status == 1 ? 0 : 1,
            })
            .then((res) => {
              this.$message({
                message: `已${detail.status == 1 ? "禁用" : "启用"}`,
                type: "success",
              });
              this.tableData[index].status = detail.status == 1 ? 0 : 1;
            });
        })
        .catch(() => {});
    },

    //基础表格查询搜索函数

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.logistics
        .getFleetList(this.from)
        .then((res) => {
          console.log(res, "数据");
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss">
.stevedoring-administration {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  //   height: 100%;
  //   padding: 10px;
  box-sizing: border-box;
  //   background: #ffffff;
  .auto-table-title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      margin-left: 10px;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }
  .fy {
    text-align: right;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
